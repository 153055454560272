import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { CATEGORY_WITH_PRODUCT_FRAGMENT } from '@/graphql/_Fragments/Category/WithProduct';
import DomainContextParams from '@/utils/types/DomainContextParams';
import ActionRule from '@/utils/enums/ActionRule';

export const EXHIBITOR_ENTITY_LIST_FRAGMENT = `
  fragment exhibitorEntityListFragment on Exhibitor {
    uid
    id
    schemaCode
    __typename
    name
    logoFileResource {
      ...fileResourceBaseFragment
    }
    categoriesInContext(context: "${DomainContextParams.company}") {
      ...categoryWithProductFragment
    }
    _actions(actions: ["${ActionRule.CAN_BOOKMARK}"]) {
       value
       key
    }
    _isBookmarked(myUid: "%authUser%")
    _isFollowed(myUid: "%authUser%")
    _isVisited(myUid: "%authUser%")
  }
  ${CATEGORY_WITH_PRODUCT_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
